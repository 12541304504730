<template>
    <div>
        {{maskiranarec}}
    </div>
</template>
<script>
export default {
    props: ["rec", "slova"],
    computed: {
        maskiranarec() {
            let novarec = "";
            for(var i=0; i<this.rec.length; i++) {
                if(this.slova.indexOf(this.rec[i]) === -1) {
                    novarec += "*";
                }
                else {
                    novarec += this.rec[i];
                }
            }
            return novarec;
        }
    }
}
</script>
<style scoped>

</style>