<template>
    <div>
        <h3> Igra vesala </h3>
        <div>
            Preostalo: {{ brojZivota }} pokusaja
        </div>
        <div>
            <vesala-rec :rec="izabranaRec" :slova="slova"></vesala-rec>
        </div>
        <div>
            <input v-model="pogadjanje" @keydown.enter="pogodi"/>
        </div>
        <div v-if="brojZivota<=0">
            <button @click="resetujIgru">Nova igra</button>
        </div>
    </div>
</template>

<script>
import VesalaRec from '../components/VesalaRec.vue'

export default {
    components: {VesalaRec},
    data: function(){
        return {
            moguceReci: ["javascript","otorinolaringolog"],
            slova: [],
            izabranaRec: "",
            pogadjanje: "",
            brojZivota: 5
        }
    },
    methods: {
        pogodi(){
            if(this.brojZivota <= 0) return;

            if(this.pogadjanje.length === 1) {
                if(this.slova.indexOf(this.pogadjanje) == -1) {
                    this.slova.push(this.pogadjanje);
                }
                if(this.izabranaRec.indexOf(this.pogadjanje) == -1) {
                    this.brojZivota--;
                }
            }
            else {
                if(this.pogadjanje === this.izabranaRec) {
                    for(var i=0; i<this.pogadjanje.length; i++)
                        this.slova.push(this.pogadjanje[i]);
                }
                else {
                    this.brojZivota--;
                }
            }
            this.pogadjanje="";
        },
        resetujIgru() {
             let r = Math.floor(Math.random()*this.moguceReci.length);
            this.izabranaRec = this.moguceReci[r];
            this.brojZivota=5;
            this.slova=[];
        }
    },
    mounted() {
       this.resetujIgru();
    }
}
</script>

<style scoped>

</style>